import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HeadingLumos from "../../../../theming/HeadingLumos";
import { getOrganizationEventParticipationsByOrganizationId } from "../../../../../redux/organizationEventParticipations/action";
import { AppState } from "../../../../../redux";
import { OrganizationEventParticipationsData } from "../../../../../models/organizationEventParticipationsData";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
  statusMainTranslationKeys,
} from "../../../../../models/enums/participationStatus.enum";
import Loader from "../../../../theming/loader/Loader";

interface BookingsManagementProps {
  organizationId: string;
}

export const BookingsManagement: React.FC<BookingsManagementProps> = (props) => {
  const { organizationId } = props;
  const { t } = useTranslation(["enums", "organization", "snackbars"]);
  const dispatch = useDispatch();
  const { organizationEventParticipationsData = [], isLoading } = useSelector(
    (state: AppState) => state.organizationEventParticipations
  );

  useEffect(() => {
    dispatch(getOrganizationEventParticipationsByOrganizationId(organizationId));
  }, [dispatch, organizationId]);

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const handleToggle = (eventName: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [eventName]: !prev[eventName],
    }));
  };

  const mapToParticipationStatus = (status: string): string => {
    return statusMainTranslationKeys[status];
  };
  const statusColumns: string[] = [
    String(ParticipationStatus.WaitingList),
    String(ParticipationStatus.Booked),
    String(ParticipationStatus.Participated),
    String(ParticipationStatus.NotParticipated),
  ];

  return (
    <Box>
      <HeadingLumos>
        {t("bookings.manageBookingsTitle", { ns: "organization" })}
      </HeadingLumos>
      <Typography>{t("bookings.manageBookingsDesc", { ns: "organization" })}</Typography>
      <Box style={{ width: "100%", marginTop: "1.25rem" }}>
        {isLoading ? (
          <Loader />
        ) : organizationEventParticipationsData.length === 0 ? (
          <Typography>
            {t("bookings.manageBookingsNoBookings", { ns: "organization" })}
          </Typography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  aria-label="collapsible table"
                  style={{ tableLayout: "fixed", width: "100%" }} // Evenly distribute columns
                >
                  <TableBody>
                    {organizationEventParticipationsData.map(
                      (eventParticipation: OrganizationEventParticipationsData) => {
                        const { event, status = {} } = eventParticipation;
                        const isExpanded = expandedRows[event] || false;

                        return (
                          <React.Fragment key={event}>
                            <TableRow>
                              <TableCell
                                align="left"
                                style={{ wordBreak: "break-word", fontWeight: "bold" }}
                              >
                                {event}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleToggle(event)}
                                >
                                  {isExpanded ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            {isExpanded ? (
                              <TableRow>
                                <TableCell
                                  colSpan={2}
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                >
                                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                      <Table
                                        size="small"
                                        aria-label="expanded-status"
                                        style={{ tableLayout: "fixed", width: "100%" }}
                                      >
                                        <TableHead>
                                          <TableRow>
                                            {statusColumns.map((statusKey) => (
                                              <TableCell
                                                key={statusKey}
                                                style={{
                                                  wordBreak: "break-word",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {t(mapToParticipationStatus(statusKey), {
                                                  ns: "enums",
                                                })}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            {statusColumns.map((statusKey) => (
                                              <TableCell
                                                key={statusKey}
                                                style={{
                                                  wordBreak: "break-word",
                                                  whiteSpace: "pre-wrap",
                                                }}
                                              >
                                                {Array.isArray(status[statusKey]) &&
                                                status[statusKey].length > 0
                                                  ? status[statusKey].join(",\n")
                                                  : ""}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            ) : null}
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default BookingsManagement;
