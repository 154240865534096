import { Box, IconButton, Typography } from "@material-ui/core";
import { DeleteForever as Close } from "@material-ui/icons";
import defaultPDF from "../../../../../assets/general/pdf_placeholder.png";
import { useTranslation } from "react-i18next";
import { MINIMUM_DUMMY_DATE_STRING } from "../../../../../constants";

type ImageWithDeleteIconParams = {
  src: string;
  alt: string;
  title: string;
  fileExtention?: string;
  hideDeleteButton?: boolean;
  validUntil?: string;
  docHasNoExpiryDate?: boolean;
  onDelete: () => void;
};

/**
 * ImageWithDeleteIcon component displays an image with an optional delete button.
 * @param {object} props - The properties of the component.
 * @param {string} src - The source URL of the image.
 * @param {string} alt - The alternative text for the image.
 * @param {string} title - The title of the delete button.
 * @param {string} [fileExtention] - The file extension (e.g., "pdf") to determine the image source.
 * @param {boolean} [hideDeleteButton] - A flag to hide the delete button.
 * @param {string} [validUntil] - The date of validity.
 * @param {boolean} [docHasNoExpiryDate] - A flag indicating if a document has no expiry date.
 * @param {function} onDelete - The function to be called when the delete button is clicked.
 * @returns {JSX.Element} - The JSX element representing the ImageWithDeleteIcon component.
 */
export const ImageWithDeleteIcon = (props: ImageWithDeleteIconParams) => {
  const { t } = useTranslation(["common"]);

  const { src, alt, title, hideDeleteButton, validUntil, docHasNoExpiryDate, onDelete } =
    props;

  let validUntilString = docHasNoExpiryDate
    ? t("docInfo.noExpiryDate", { ns: "participationTypes" })
    : validUntil;
  if (validUntilString === MINIMUM_DUMMY_DATE_STRING) {
    validUntilString = t("docInfo.noExpiryDate", { ns: "participationTypes" });
  }

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <img
        id="group-membership-image"
        src={props?.fileExtention === "pdf" ? defaultPDF : src}
        alt={alt}
        className="w-100"
        style={{
          height: 200,
          width: "100%",
          objectFit: "cover",
          position: "relative",
        }}
      />
      {hideDeleteButton || (
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0.2rem",
          }}
        >
          <IconButton
            title={title}
            style={{
              backgroundColor: "transparent",
              paddingLeft: 0,
              padding: 0,
            }}
            onClick={onDelete}
            id="group-membership-delete-component"
          >
            <Close color="error" id="group-membership-delete-icon" />
            <Typography
              color="error"
              style={{ fontSize: "1rem" }}
              id="group-membership-delete-text"
            >
              {t("delete", { ns: "common" })}
            </Typography>
          </IconButton>
          {validUntil ? (
            <Typography style={{ fontSize: "1rem" }}>
              {t("docInfo.validUntil", { ns: "participationTypes" })}: {validUntilString}
            </Typography>
          ) : null}
        </Box>
      )}
    </div>
  );
};

ImageWithDeleteIcon.defaultProps = {
  docHasNoExpiryDate: false,
  fileExtention: "",
  hideDeleteButton: false,
  validUntil: MINIMUM_DUMMY_DATE_STRING,
};
